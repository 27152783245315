/*! main.js | Bulkit | CSS Ninja */

/* ==========================================================================
Core JS file
========================================================================== */

"use strict";

//1. Preload page
initPageLoader()
var appsumo_api_url = 'https://appsumo.crigloo.com/';
//var appsumo_api_url = 'http://127.0.0.1:8009/';
console.log(appsumo_api_url);
// 20-20 js
var ls2020JsOptions = {
	default_offset_pct: 0.5,
	orientation: 'horizontal',
	before_label: 'Before',
	after_label: 'After',
	no_overlay: true,
	move_slider_on_hover: true,
	move_with_handle_only: false,
	click_to_move: false
}

function handle2020Animation() {
	$('.twentytwenty-handle').each(function () {
		if ($(this).is(':visible')) {
			if ($('.cbc-before-after').hasClass('hover-shake')) {
				$('.cbc-before-after').removeClass('hover-shake')
			} else {
				$('.cbc-before-after').addClass('hover-shake');
			}
		}
	});
}

var urlParams = new URLSearchParams(window.location.search);

function getSiginHostUrl() {
	var domain_name = "https://app.crigloo.com";
	var url = window.location.href;
	if (url.includes('beta')) {
		domain_name = "https://bng.crigloo.com";
	} else if (url.includes("127.0.0.1") || url.includes("localhost") || url.includes("alpha")) {
		domain_name = "https://ang.crigloo.com";
	}
	return domain_name;
}
function valid_domain(domain_name) {
	console.log(domain_name);
	// strip off "http://" and/or "www."
	domain_name = domain_name.replace("https://", "");
	domain_name = domain_name.replace("http://", "");
	domain_name = domain_name.replace("www.", "");

	var reg = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
	if (reg.test(domain_name) == false) {
		return false;
	}
	return true;
}
var has_spcial_char = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
var has_number = /^[0-9]+$/;

function validateEmail(t) {
	return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(t).toLowerCase())
}

window.addEventListener("load", function (event) {
	lazyload();
});

function pricing() {
	if (currenySymbol == 'US') {
		window.location.href = "pricing-us.html"
	}	
if (locationTimezone.timeZone == "Asia/Calcutta" || locationTimezone.timeZone == "Asia/Kolkata") {
	window.location.href = "pricing.html"
}
if (locationTimezone.timeZone == "Europe/London") {
	window.location.href = "pricing-eu.html"
}
}

function contact_us(t, e) {
	// $(".register-msg").addClass("d-none").removeClass("text-danger").removeClass("text-success");
	return !1
}
// function contact_us(t, e) {
// 	$(".register-msg").addClass("d-none").removeClass("text-danger").removeClass("text-success");
// 	toastr.remove();
// 	//var api_url = "http://localhost:8001/";
// 	var api_url = "https://vg153jb8o3.execute-api.us-east-1.amazonaws.com/api/";
// 	if ("contact_us" == e || "register" == e) {
// 		var s = "";
// 		if ("contact_us" == e) {
// 			api_url += "contact_us";
// 			var a = $.trim($("#contact_us_name").val()),
// 				n = $.trim($("#contact_us_email").val()),
// 				r = $.trim($("#contact_us_message").val());
// 			if ("" == a) return $("#contact_us_name").focus(), !1;
// 			if ("" == n) return $("#contact_us_email").focus(), !1;
// 			if (!validateEmail(n)) return $("#contact_us_email").focus(), !1;
// 			if ("" == r) return $("#contact_us_message").focus(), !1;
// 			var url = window.location.href;
// 			var url_arr = url.split("/");
// 			if (url_arr[2] == "127.0.0.1:8000") {
// 				domain_name = "alpha.crigloo.com";
// 			} else {
// 				domain_name = url_arr[2];
// 			}
// 			if (domain_name == "") {
// 				domain_name = "alpha.crigloo.com";
// 			}
// 			s = JSON.stringify({
// 				"contact_us_name": a,
// 				"contact_us_email": n,
// 				"contact_us_message": r,
// 				"domain_name": domain_name,
// 			})
// 		} else if ("register" == e) {
// 			//$("#step2").find(".step-new-content").css("display", "block");
// 			api_url += "user_register";
// 			var i = $.trim($("#FormFirstName").val()),
// 				o = $.trim($("#FormLastName").val()),
// 				u = $.trim($("#emailInputSignin").val()),
// 				c = $.trim($("#FormPassword").val()),
// 				//m = $.trim($("#Companyname").val()),
// 				l = $.trim($("select#signup_business_type").find("option:selected").val());
// 			//f = $.trim($("#workPhone").val());
// 			var monthly_online_advertising_budget = $.trim($("#signup_budget").find("option:selected").val());
// 			//var industry_type = $.trim($("select#signup_industry_type").find("option:selected").val());
// 			var website = $.trim($("#website").val());
// 			if ("" == i) return $("#FormFirstName").focus(), !1;
// 			if ("" == o) return $("#FormLastName").focus(), !1;
// 			if ("" == u) return $("#emailInputSignin").focus(), !1;
// 			if (!validateEmail(u)) return $("#emailInputSignin").focus(), !1;
// 			// if(u.indexOf("gmail") !== -1 || u.indexOf("yahoo") !== -1){
// 			// 	$("#emailInputSignin").focus();
// 			// 	toastr.error("Please provide valid company email address");
// 			// 	return false;
// 			// }
// 			if ("" == c) return $("#FormPassword").focus(), !1;
// 			var chk_pwd_has_cap = [];
// 			var chk_pwd_has_num = [];
// 			var pwd = c.split("");
// 			if (pwd.length < 8) {
// 				$("#FormPassword").focus();
// 				toastr.error("Password atleast 8 letters or more.");
// 				return false;
// 			}
// 			for (var pwd_i = 0; pwd_i < pwd.length; pwd_i++) {
// 				if (pwd[pwd_i] === pwd[pwd_i].toUpperCase() && pwd[pwd_i] !== pwd[pwd_i].toLowerCase()) {
// 					chk_pwd_has_cap.push("1");
// 				}
// 				if (has_number.test(pwd[pwd_i])) {
// 					chk_pwd_has_num.push("1");
// 				}
// 			}
// 			if (chk_pwd_has_cap.length == 0) {
// 				$("#FormPassword").focus();
// 				toastr.error("Password should contain one capital letter.");
// 				return false;
// 			}
// 			if (chk_pwd_has_num.length == 0) {
// 				$("#FormPassword").focus();
// 				toastr.error("Password should contain one numeric.");
// 				return false;
// 			}
// 			if (!has_spcial_char.test(c)) {
// 				$("#FormPassword").focus();
// 				toastr.error("Password should contain one special letter.");
// 				return false;
// 			}
// 			if ("" == l) return $("#signup_business_type").focus(), !1;
// 			/* if(l == "Brand"){
// 				if(industry_type == ""){
// 					$("#signup_industry_type").focus();
// 					toastr.error("Industry type required.");

// 					return false;
// 				}
// 			} */
// 			if (monthly_online_advertising_budget == "") {
// 				$("#signup_budget").focus();
// 				toastr.error("What is your Monthly Online Advertising Budget? required.");
// 				return false;
// 			}
// 			//if ("" == m) return $("#Companyname").focus(), !1;
// 			//if ("" == f) return $("#workPhone").focus(), !1;
// 			if ("" == website) return $("#website").focus(), !1;
// 			if (!valid_domain(website)) {
// 				toastr.error("Please enter valid website.");
// 				return $("#website").focus(), !1;
// 			}
// 			var url = window.location.href;
// 			var url_arr = url.split("/");
// 			if (url_arr[2] == "127.0.0.1:8000") {
// 				domain_name = "alpha.crigloo.com";
// 			} else {
// 				domain_name = url_arr[2];
// 			}
// 			if (domain_name == "") {
// 				domain_name = "alpha.crigloo.com";
// 			}
// 			var industry_type = "";
// 			var m = "";
// 			var f = "";
// 			s = JSON.stringify({
// 				"first_name": i,
// 				"last_name": o,
// 				"user_email": u,
// 				"user_password": c,
// 				"company_name": m,
// 				"industry_type": industry_type,
// 				"business_type": l,
// 				"user_phone": f,
// 				"monthly_online_advertising_budget": monthly_online_advertising_budget,
// 				"website": website,
// 				"domain_name": domain_name,
// 			})
// 			//$("#step2").find(".wait-feedback").remove();
// 			//$("#step2").removeClass("done").removeClass("feedbacking");
// 		}
// 		"contact_us" == e ? $(t).html("Processing...") : "register" == e && $(t).text("Processing...").addClass("disabled"), $.ajax({
// 			url: api_url,
// 			type: "post",
// 			data: s,
// 			dataType: "json",
// 			contentType: "application/json; charset=utf-8",
// 			crossDomain: !0,
// 			error: function (s) {
// 				"contact_us" == e ? $(t).html("Send") : "register" == e && $(t).text("Register")
// 			},
// 			success: function (s) {
// 				if (typeof (s['result']) != 'undefined' && s['result'] == "success" && typeof (s['message']) != 'undefined') {
// 					"contact_us" == e ? ($(t).html("Send"), $("#contact_us_form_p").find("input").val(""), $("#contact_us_form_p").find("textarea").val(""), toastr.success("<b>" + a + " </b> Please stay tuned and we will get back to you shortly.", "Thank You!", {
// 						timeOut: 5e3,
// 						progressBar: !0
// 					})) : "register" == e && ($(t).text("Request Demo"), $("#register_form_p").find("input").val(""), $("#register_form_p").find("#signup_business_type").find("option:first").prop("selected", true), $("#signup_budget").val(""))
// 					if ("register" == e) {
// 						$("#saveRegisterForm").removeClass("was-validated");
// 						setTimeout(function () {
// 							window.location.href = "/register_success.html"
// 						}, 1500);
// 						$(".register-msg").removeClass("d-none").addClass("text-success").html("<strong>Thank you for registering. Please check your email for verification. Email sender id is info@crigloo.com</strong>");
// 					}
// 				} else if (typeof (s['result']) != 'undefined' && s['result'] == "error" && typeof (s['message']) != 'undefined') {
// 					if ("register" == e) {
// 						$(".register-msg").removeClass("d-none").addClass("text-danger").html("<strong>" + s['message'] + "</strong>");
// 					} else {
// 						toastr.error(s['message']);
// 					}
// 				} else {
// 					if ("register" == e) {
// 						$(".register-msg").removeClass("d-none").addClass("text-danger").html("<strong>Something went wrong. Please contact us at info@crigloo.com.</strong>");
// 					} else {
// 						toastr.error("Something went wrong. Please contact us at info@crigloo.com.");
// 					}
// 				}
// 				if (e == "contact_us") {
// 					$(t).text("Send").removeClass("disabled");
// 				} else if (e == "register") {
// 					$(t).text("Request Free Trial").removeClass("disabled");
// 				}
// 			}
// 		})
// 	}
// 	return !1
// }

var locationTimezone = Intl.DateTimeFormat().resolvedOptions();
// let INPricing = {
// 	smeMo: "₹5,000",
// 	midMo: "₹10,000",
// 	largeMo: "₹20,000",
// 	smeYr: "₹50,000",
// 	midYr: "₹1,00,000",
// 	largeYr: "₹2,00,000",
// }
var INPricing ={};
var defaultPricing ={};
var EUPricing ={};
var url = window.location.href;
function getPriceHostUrl() {
	 INPricing = {
		smeYr: {"text":"₹50,000", "val":"5"},
		smeMo: {"text":"₹5,000", "val":"6"},
		midYr: {"text":"₹1,50,000", "val":"7"},
		midMo: {"text":"₹15,000", "val":"8"},
		largeYr: {"text":"₹3,00,000", "val":"9"},
		largeMo: {"text":"₹30,000", "val":"10"},
		// ExtralargeYr: {"text":"", "val":""},
		// ExtralargeMo: {"text":"", "val":""},
		
	}
	
	// Old
	//  defaultPricing = {
	// 	smeYr: {"text":"$500.00", "val":"12"},
	// 	smeMo: {"text":"$50.00", "val":"11"},
	// 	midYr: {"text":"$800.00", "val":"14"},
	// 	midMo: {"text":"$80.00", "val":"13"},
	// 	largeYr: {"text":"$1000.00", "val":"16"},
	// 	largeMo: {"text":"$100.00", "val":"15"},
	// 	ExlargeYr: {"text":"$2490.00", "val":"18"},
	// 	ExlargeMo: {"text":"$249.00", "val":"17"},
	// }

	defaultPricing = {
		smeYr: { "text": "$290.00", "val": "12" },
		smeMo: { "text": "$29.00", "val": "11" },
		midYr: { "text": "$490.00", "val": "14" },
		midMo: { "text": "$49.00", "val": "13" },
		largeYr: { "text": "$790.00", "val": "16" },
		largeMo: { "text": "$79.00", "val": "15" },
		ExlargeYr: { "text": "$2490.00", "val": "18" },
		ExlargeMo: { "text": "$249.00", "val": "17" },
		// Strike Price  
		tier1Yr: { "text": "$580.00", "val": "0" },
		tier1Mo: { "text": "$58.00", "val": "0" },
		tier2Yr: { "text": "$980.00", "val": "0" },
		tier2Mo: { "text": "$98.00", "val": "0" },
		tier3Yr: { "text": "$1580.00", "val": "0" },
		tier3Mo: { "text": "$158.00", "val": "0" },
	}
	
	// Offer 1
// 	defaultPricing = {
// 	   smeYr: {"text":"$250.00", "val":"21"},
// 	   smeMo: {"text":"$25.00", "val":"20"},
// 	   midYr: {"text":"$400.00", "val":"23"},
// 	   midMo: {"text":"$40.00", "val":"22"},
// 	   largeYr: {"text":"$500.00", "val":"25"},
// 	   largeMo: {"text":"$50.00", "val":"24"},
// 	   ExlargeYr: {"text":"$2490.00", "val":"18"},
// 	   ExlargeMo: {"text":"$249.00", "val":"17"},
// 	   // Strike Price  
// 	   tier1Yr: {"text":"$500.00", "val":"0"},
// 	   tier1Mo: {"text":"$50.00", "val":"0"},
// 	   tier2Yr: {"text":"$800.00", "val":"0"},
// 	   tier2Mo: {"text":"$80.00", "val":"0"},
// 	   tier3Yr: {"text":"$1000.00", "val":"0"},
// 	   tier3Mo: {"text":"$100.00", "val":"0"},
// 	   offer:'50'
//    }
	
   // Offer 2
//  if (url.includes("bfcm_jenny30") || sessionStorage.getItem('fpr') == "bfcm_jenny30") {
//    defaultPricing = {
// 	  smeYr: {"text":"$175.00", "val":"21"},
// 	  smeMo: {"text":"$17.50", "val":"20"},
// 	  midYr: {"text":"$280.00", "val":"23"},
// 	  midMo: {"text":"$28.00", "val":"22"},
// 	  largeYr: {"text":"$350.00", "val":"25"},
// 	  largeMo: {"text":"$35.00", "val":"24"},
// 	  ExlargeYr: {"text":"$2490.00", "val":"18"},
// 	  ExlargeMo: {"text":"$249.00", "val":"17"},
// 	  // Strike Price  
// 	  tier1Yr: {"text":"$500.00", "val":"0"},
// 	  tier1Mo: {"text":"$50.00", "val":"0"},
// 	  tier2Yr: {"text":"$800.00", "val":"0"},
// 	  tier2Mo: {"text":"$80.00", "val":"0"},
// 	  tier3Yr: {"text":"$1000.00", "val":"0"},
// 	  tier3Mo: {"text":"$100.00", "val":"0"},
// 	  offer:'65'
//   }
//  }
		
	EUPricing = {
		smeYr: {"text":"€50.00", "val":"19"},
		smeMo: {"text":"€10.00", "val":"20"},
		midYr: {"text":"€200.00", "val":"21"},
		midMo: {"text":"€20.00", "val":"22"},
		largeYr: {"text":"€300.00", "val":"23"},
		largeMo: {"text":"€30.00", "val":"24"},
		ExlargeYr: {"text":"€400.00", "val":"25"},
		ExlargeMo: {"text":"€40.00", "val":"26"},
	}
 if (url.includes("127.0.0.1") || url.includes("localhost") || url.includes("alpha")) {
		 INPricing = {
			smeYr: {"text":"₹50,000", "val":"5"},
			smeMo: {"text":"₹5,000", "val":"6"},
			midYr: {"text":"₹1,50,000", "val":"7"},
			midMo: {"text":"₹15,000", "val":"8"},
			largeYr: {"text":"₹3,00,000", "val":"9"},
			largeMo: {"text":"₹30,000", "val":"10"},
			// ExtralargeYr: {"text":"", "val":""},
			// ExtralargeMo: {"text":"", "val":""},
			
		}
		// Old
		//  defaultPricing = {
		// 	smeYr: {"text":"$500.00", "val":"11"},
		// 	smeMo: {"text":"$50.00", "val":"12"},
		// 	midYr: {"text":"$800.00", "val":"13"},
		// 	midMo: {"text":"$80.00", "val":"14"},
		// 	largeYr: {"text":"$1000.00", "val":"15"},
		// 	largeMo: {"text":"$100.00", "val":"16"},
		// 	ExlargeYr: {"text":"$2490.00", "val":"18"},
		// 	ExlargeMo: {"text":"$249.00", "val":"17"},
		// }

		defaultPricing = {
			smeYr: { "text": "$290.00", "val": "12" },
			smeMo: { "text": "$29.00", "val": "11" },
			midYr: { "text": "$490.00", "val": "14" },
			midMo: { "text": "$49.00", "val": "13" },
			largeYr: { "text": "$790.00", "val": "16" },
			largeMo: { "text": "$79.00", "val": "15" },
			ExlargeYr: { "text": "$2490.00", "val": "18" },
			ExlargeMo: { "text": "$249.00", "val": "17" },
			// Strike Price  
			tier1Yr: { "text": "$580.00", "val": "0" },
			tier1Mo: { "text": "$58.00", "val": "0" },
			tier2Yr: { "text": "$980.00", "val": "0" },
			tier2Mo: { "text": "$98.00", "val": "0" },
			tier3Yr: { "text": "$1580.00", "val": "0" },
			tier3Mo: { "text": "$158.00", "val": "0" },
		}
		
		// Offer 1
		// defaultPricing = {
		//    smeYr: {"text":"$250.00", "val":"11"},
		//    smeMo: {"text":"$25.00", "val":"12"},
		//    midYr: {"text":"$400.00", "val":"13"},
		//    midMo: {"text":"$40.00", "val":"14"},
		//    largeYr: {"text":"$500.00", "val":"15"},
		//    largeMo: {"text":"$50.00", "val":"16"},
		//    ExlargeYr: {"text":"$2490.00", "val":"18"},
		//    ExlargeMo: {"text":"$249.00", "val":"17"},
		//    // Strike Price  
		//    tier1Yr: {"text":"$500.00", "val":"0"},
		//    tier1Mo: {"text":"$50.00", "val":"0"},
		//    tier2Yr: {"text":"$800.00", "val":"0"},
		//    tier2Mo: {"text":"$80.00", "val":"0"},
		//    tier3Yr: {"text":"$1000.00", "val":"0"},
		//    tier3Mo: {"text":"$100.00", "val":"0"},
		//    offer:'50'
	    // }
		
	//    Offer 2
	// if (url.includes("bfcm_jenny30")) {
	//    defaultPricing = {
	// 	  smeYr: {"text":"$175.00", "val":"11"},
	// 	  smeMo: {"text":"$17.50", "val":"12"},
	// 	  midYr: {"text":"$280.00", "val":"13"},
	// 	  midMo: {"text":"$28.00", "val":"14"},
	// 	  largeYr: {"text":"$350.00", "val":"15"},
	// 	  largeMo: {"text":"$35.00", "val":"16"},
	// 	  ExlargeYr: {"text":"$2490.00", "val":"18"},
	// 	  ExlargeMo: {"text":"$249.00", "val":"17"},
	// 	  // Strike Price
	// 	  tier1Yr: {"text":"$500.00", "val":"0"},
	// 	  tier1Mo: {"text":"$50.00", "val":"0"},
	// 	  tier2Yr: {"text":"$800.00", "val":"0"},
	// 	  tier2Mo: {"text":"$80.00", "val":"0"},
	// 	  tier3Yr: {"text":"$1000.00", "val":"0"},
	// 	  tier3Mo: {"text":"$100.00", "val":"0"},
	// 	  offer:'65'
	//   }
	// }


	// Offer 1
	// defaultPricing = {
	// 	smeYr: {"text":"$250.00", "val":"21"},
	//    smeMo: {"text":"$25.00", "val":"20"},
	//    midYr: {"text":"$400.00", "val":"23"},
	//    midMo: {"text":"$40.00", "val":"22"},
	//    largeYr: {"text":"$500.00", "val":"25"},
	//    largeMo: {"text":"$50.00", "val":"24"},
	// 	ExlargeYr: {"text":"$2490.00", "val":"18"},
	// 	ExlargeMo: {"text":"$249.00", "val":"17"},
	// 	// Strike Price  
	// 	tier1Yr: {"text":"$500.00", "val":"0"},
	// 	tier1Mo: {"text":"$50.00", "val":"0"},
	// 	tier2Yr: {"text":"$800.00", "val":"0"},
	// 	tier2Mo: {"text":"$80.00", "val":"0"},
	// 	tier3Yr: {"text":"$1000.00", "val":"0"},
	// 	tier3Mo: {"text":"$100.00", "val":"0"},
	// 	offer:'50'
	// }
	 
	// Offer 2
//   if (url.includes("bfcm_jenny30") || sessionStorage.getItem('fpr') == "bfcm_jenny30") {
// 	defaultPricing = {
// 		smeYr: {"text":"$175.00", "val":"21"},
// 		smeMo: {"text":"$17.50", "val":"20"},
// 		midYr: {"text":"$280.00", "val":"23"},
// 		midMo: {"text":"$28.00", "val":"22"},
// 		largeYr: {"text":"$350.00", "val":"25"},
// 		largeMo: {"text":"$35.00", "val":"24"},
// 	   ExlargeYr: {"text":"$2490.00", "val":"18"},
// 	   ExlargeMo: {"text":"$249.00", "val":"17"},
// 	   // Strike Price  
// 	   tier1Yr: {"text":"$500.00", "val":"0"},
// 	   tier1Mo: {"text":"$50.00", "val":"0"},
// 	   tier2Yr: {"text":"$800.00", "val":"0"},
// 	   tier2Mo: {"text":"$80.00", "val":"0"},
// 	   tier3Yr: {"text":"$1000.00", "val":"0"},
// 	   tier3Mo: {"text":"$100.00", "val":"0"},
// 	   offer:'65'
//    }	
// }
		EUPricing = {
		   smeYr: {"text":"€50.00", "val":"19"},
		   smeMo: {"text":"€10.00", "val":"20"},
		   midYr: {"text":"€200.00", "val":"21"},
		   midMo: {"text":"€20.00", "val":"22"},
		   largeYr: {"text":"€300.00", "val":"23"},
		   largeMo: {"text":"€30.00", "val":"24"},
		   ExlargeYr: {"text":"€400.00", "val":"25"},
		   ExlargeMo: {"text":"€40.00", "val":"26"},
	   }
	}
}
getPriceHostUrl();
// var INPricing = {
// 	smeYr: {"text":"₹50,000", "val":"5"},
// 	smeMo: {"text":"₹5,000", "val":"6"},
// 	midYr: {"text":"₹1,50,000", "val":"7"},
// 	midMo: {"text":"₹15,000", "val":"8"},
// 	largeYr: {"text":"₹3,00,000", "val":"9"},
// 	largeMo: {"text":"₹30,000", "val":"10"},
// 	// ExtralargeYr: {"text":"", "val":""},
// 	// ExtralargeMo: {"text":"", "val":""},
	
// }
// // var defaultPricing = {
// // 	smeMo: "$29.00",
// // 	midMo: "$49.00",
// // 	largeMo: "$79.00",
// // 	smeYr: "$290.00",
// // 	midYr: "$490.00",
// // 	largeYr: "$790.00"
// // }

// var defaultPricing = {
// 	smeYr: {"text":"$290.00", "val":"11"},
// 	smeMo: {"text":"$29.00", "val":"12"},
// 	midYr: {"text":"$490.00", "val":"13"},
// 	midMo: {"text":"$49.00", "val":"14"},
// 	largeYr: {"text":"$790.00", "val":"15"},
// 	largeMo: {"text":"$79.00", "val":"16"},
// 	ExlargeYr: {"text":"$2490.00", "val":"18"},
// 	ExlargeMo: {"text":"$249.00", "val":"17"},
// }
var registerUrlSearchParams = '', registerParams = '';
if (window.location.href.includes('/register.html')) {
	registerUrlSearchParams = new URLSearchParams(window.location.search);
	registerParams = Object.fromEntries(registerUrlSearchParams.entries());
}

var currenySymbol = 'US';
if (locationTimezone.timeZone == "Asia/Calcutta" || locationTimezone.timeZone == "Asia/Kolkata") {
	defaultPricing = INPricing
	currenySymbol = 'IN';
}
//  if (locationTimezone.timeZone == "Europe/London") {
// 	defaultPricing = EUPricing
// 	currenySymbol = 'EU';
// }

$(document).ready(function ($) {
	var lsHrefLink= window.location.href;
	var lsHrefVal = '';
	if (lsHrefLink.includes('fpr')) {
		lsHrefVal = lsHrefLink.split('fpr=')[1];
		sessionStorage.setItem('fpr',lsHrefVal);
	}

	//appsumo lauch sheet no:44 Cancelled
	// $('#website').on('blur', function(e){ 
	// 	var lsValue = $(this).val();
	// 	if(lsValue == "" || lsValue ==null){
	// 		this.value = "https://"
	// 	}
	//  });

	if(typeof(CreateSubDropdown) == 'function')
	CreateSubDropdown();


	//2. Lazy loading
	var el = document.querySelectorAll('[data-lazy-load]');
	var observer = lozad(el, {
		loaded: function (el) {
			// Custom implementation on a loaded element
			el.parentNode.classList.add('loaded');
		}
	});

	observer.observe();

	//3. Change to demo content (if env)
	if (env === 'development') {
		changeDemoImages();
	}

	$('#myModal').on('shown.bs.modal', function () {
		$('#myInput').focus()
	});

	$('#cb_video').on('hidden.bs.modal', function (e) {
		// do something...
		$('#cb_video iframe').attr("src", $("#cb_video iframe").attr("src"));
	});

	$(".twentytwenty-container[data-orientation!='vertical']").twentytwenty(ls2020JsOptions);
	handle2020Animation()

	//4. Init Feather icons
	feather.replace();

	(function ($) {
		$('.tab ul.tabs').addClass('active').find('> li:eq(0)').addClass('current');
		$('.tab ul.tabs li a').on('click', function (g) {
			var tab = $(this).closest('.tab'),
				index = $(this).closest('li').index();
			tab.find('ul.tabs > li').removeClass('current');
			$(this).closest('li').addClass('current');
			tab.find('.tab_content').find('div.tabs_item').not('div.tabs_item:eq(' + index + ')').slideUp();
			tab.find('.tab_content').find('div.tabs_item:eq(' + index + ')').slideDown();
			g.preventDefault();
		});
		if ((typeof crver !== 'undefined') && (typeof crcol !== 'undefined') && crver && crcol) {
			var footerVersionText = '';
			if (crcol == 'red')
				footerVersionText = 'text-danger';
			else if (crcol == 'green')
				footerVersionText = 'text-success';
			else if (crcol == 'blue')
				footerVersionText = 'text-primary';

			if (footerVersionText)
				$('#footerVersionText').addClass(footerVersionText);
			$('#footerVersion').text(crver);
		}

		$("#register-button,#resetFormSubmit,#contactFormSubmit").on('click', function (event) {

			// make selected form variable
			var vForm = $('#' + $(this).attr('form-id'))
			if (vForm[0].checkValidity() === false) {
				event.preventDefault();
				event.stopPropagation();
			} else {

			}

			// Add bootstrap 4 was-validated classes to trigger validation messages
			vForm.addClass('was-validated');
			var lnoffset= $('.was-validated :invalid').first().offset().top - 100;
			if(!isNaN(lnoffset)){
				$('html,body').animate({ scrollTop:lnoffset  }, 'slow');
			}
			$('.was-validated :invalid').first().focus();
		});

		$(".sign-in").on('click', function () {
			window.location.href = getSiginHostUrl()
			// var r = $(this).attr("data-href");
			// urlParams.has("r_url") && "" != urlParams.get("r_url") && (r += "?r_url=" + urlParams.get("r_url")), window.location.href = r
		})
		$("#contact_us_clear").on('click', function () {
			$("#contact_us_form_p").find("input").val("");
			$("#contact_us_form_p").find("textarea").val("");
		})
		// $('#registerForm.stepper').mdbStepper();
		// pricing Toogle button
		// $("#cbc_pricing_toogle").on('click', function () {
		// 	$(this).closest(".switch").find("#cbc_pricing_monthly").removeClass("text-info").removeClass("grey-text");
		// 	$(this).closest(".switch").find("#cbc_pricing_yearly").removeClass("text-info").removeClass("grey-text");
		// 	if ($(this).is(":checked")) {
		// 		//Yearly
		// 		$(this).closest(".switch").find("#cbc_pricing_yearly").addClass("text-info");
		// 		$(this).closest(".switch").find("#cbc_pricing_monthly").addClass("grey-text");
		// 		$('.cbcYearlyOffer').removeClass('d-none');
		// 		$('.cbcMonthlyOffer').addClass('d-none');
		// 		$('.cbcMonYrToggle').html('/year');
		// 		var cbcSilverOldVal = 300,
		// 			cbcGoldOldVal = 1000,
		// 			cbcPlatinumOldVal = 3000;
		// 		// $(".cbcSilverOld").html(cbcSilverOldVal.toFixed(2));
		// 		// $(".cbcSilverNew").html(cbcSilverOldVal / 2);
		// 		// $(".cbcGoldOld").html(cbcGoldOldVal.toFixed(2));
		// 		// $(".cbcGoldNew").html(cbcGoldOldVal / 2);
		// 		// $(".cbcPlatinumOld").html(cbcPlatinumOldVal.toFixed(2));
		// 		// $(".cbcPlatinumNew").html(cbcPlatinumOldVal / 2);
		// 		$(".cbcSilverNew").html(cbcSilverOldVal);
		// 		$(".cbcGoldNew").html(cbcGoldOldVal);
		// 		$(".cbcPlatinumNew").html(cbcPlatinumOldVal);
		// 		NumberCounter();
		// 	} else {
		// 		//Monthly
		// 		$(this).closest(".switch").find("#cbc_pricing_monthly").addClass("text-info");
		// 		$(this).closest(".switch").find("#cbc_pricing_yearly").addClass("grey-text");
		// 		$('.cbcMonthlyOffer').removeClass('d-none');
		// 		$('.cbcYearlyOffer').addClass('d-none');
		// 		$('.cbcMonYrToggle').html('/month');
		// 		var cbcSilverNewVal = 30,
		// 			cbcGoldNewVal = 100,
		// 			cbcPlatinumNewVal = 300;
		// 		// $(".cbcSilverOld").html(cbcSilverNewVal.toFixed(2));
		// 		// $(".cbcSilverNew").html(cbcSilverNewVal / 2);
		// 		// $(".cbcGoldOld").html(cbcGoldNewVal.toFixed(2));
		// 		// $(".cbcGoldNew").html(cbcGoldNewVal / 2);
		// 		// $(".cbcPlatinumOld").html(cbcPlatinumNewVal.toFixed(2));
		// 		// $(".cbcPlatinumNew").html(cbcPlatinumNewVal / 2);
		// 		$(".cbcSilverNew").html(cbcSilverNewVal);
		// 		$(".cbcGoldNew").html(cbcGoldNewVal);
		// 		$(".cbcPlatinumNew").html(cbcPlatinumNewVal);
		// 		NumberCounter();
		// 	}
		// });
		// NumberCounter();
	})(jQuery);

	$('input:-webkit-autofill').each(function (i, element) {
		$(this).siblings('label').addClass('active');
	});

	$('input[type=password]').keyup(function () {
		var pswd = $(this).val();
		//validate the length
		if (pswd.length < 8) {
			$('#length').removeClass('valid').addClass('invalid');
		} else {
			$('#length').removeClass('invalid').addClass('valid');
		}
		//validate letter
		// if (pswd.match(/[A-z]/)) {
		// 	$('#letter').removeClass('invalid').addClass('valid');
		// } else {
		// 	$('#letter').removeClass('valid').addClass('invalid');
		// }
		
		//validate lowercase letter
		if (pswd.match(/[a-z]/)){
			$('#lowercase').removeClass('invalid').addClass('valid');
		} else {
			$('#lowercase').removeClass('valid').addClass('invalid');
		}
		
		// //validate capital letter
		// if (pswd.match(/[A-Z]/)) {
		// 	$('#capital').removeClass('invalid').addClass('valid');
		// } else {
		// 	$('#capital').removeClass('valid').addClass('invalid');
		// }

		//validate number
		if (pswd.match(/\d/)) {
			$('#number').removeClass('invalid').addClass('valid');
		} else {
			$('#number').removeClass('valid').addClass('invalid');
		}


		// //validate special chars
		// if (pswd.match(/(?=.*[!@#$%^&*])/)) {
		// 	$('#chars').removeClass('invalid').addClass('valid');
		// } else {
		// 	$('#chars').removeClass('valid').addClass('invalid');
		// }

	}).focus(function () {
		$('#pswd_info').show();

	}).blur(function () {
		$('#pswd_info').hide();
	});
	//5. Init Layout
	initNavbar();
	initLandingNavbar();
	initMobileMenu();
	initLandingMobileMenu();
	initEcommerceNavbar();
	initNavbarDropdown();
	initSidebar();
	initThemeSwitcher();
	initBackgroundImages();

	//6. Components
	initSlider();
	initDropdowns();
	initTabsNav();
	initNavigationTabs();
	initVerticalTabs();
	initMediaCards();
	initTiltCards();
	initPopovers();
	initTooltips();
	initModals();
	initCounters();
	initSimpleAccordion();
	initAccordions();
	initToasts();
	initCountdown();

	//7. Carousels
	initBasicCarousel();
	initVerticalCarousel();
	initFlatCarousel();
	initImageCarousel();
	initSingleImageCarousel();
	initMultipleImagesCarousel();

	//8. Forms
	initDatepicker();
	initTimepicker();
	initDatepickerAlt();
	initChosenSelects();
	initMaterialSelect();
	initAutocompletes();
	initFileInputs();
	initRangeInput();
	initRangeInputs();
	initJqueryTagInput();
	initBulmaTags();
	initBulmaSteps();
	initBulmaIconpicker();
	initBulmaCalendar();
	initComboBox();
	initImageComboBox();
	initStackedComboBox();
	initFileUploader();

	//9. Video
	initVideoEmbed();
	initBackgroundVideo();
	initPlayers();

	//10. Demo
	initDemo();
	initScrollspyNav();
	initParallax();
	initBackToTop();

	//11. Utility functions
	initGitem();
	initAnchorScroll();
	initQuickview();
	initScrollReveal();
	initMarquee();

	//12. Page specific methods
	initMockup();
	initClientsCarousel();
	initPeopleCarousel();
	initCustomCarousel();
	initCarousel();
	initLandingCarousel();
	initTestimonials();
	initCharacterTestimonials();
	initPricing();
	initPricingCarousel();
	initTabbedPricing();
	initFreelancerPricing();
	initSwitchPricing();
	initBoxedPricing();
	initOnePagePricing();
	//initBlog();
	initSearchBox();
	initNavigationDots();
	initFaq();
	initAuth();
	initAnimations();
	initCanvas();
	initParticles();
	initAnimatedSvg();
	initChatWidget();
	initContactToggler();
	initGoogleMap();
	initMapBox();
	initCodeTabs();

	// kannan code
	var query_string_obj = {}
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		query_string_obj[pair[0]] = pair[1];
	}
	var query_string_obj_key_lst = Object.keys(query_string_obj);
	if (query_string_obj_key_lst.indexOf('code') != -1 && query_string_obj_key_lst.indexOf('user_id') != -1) {
		var code = query_string_obj['code'];
		var user_id = query_string_obj['user_id'];
		$.ajax({
			url: appsumo_api_url + "get_user_email",
			type: "post",
			data: JSON.stringify({ "code": code, "user_id": user_id }),
			dataType: "json",
			contentType: "application/json; charset=utf-8",
			crossDomain: !0,
			error: function (o) {
				console.log(o);
			},
			success: function (r) {
				var r_key_lst = Object.keys(r);
				if (r_key_lst.indexOf('result') != -1 && r_key_lst.indexOf('message') != -1) {
					if (r['result'] === "success") {
						var r_m_key_lst = Object.keys(r['message']);
						if (r_m_key_lst.indexOf('user_email') != -1) {
							var user_email = r['message']['user_email'];
							$("#emailInputSignin").val(user_email);
						}
					} else {

					}
				}
			}
		});
	}
});
var url = window.location.href;
var url_arr = url.split("/");
console.log(url_arr);
var api_domain_name = '';
if (url_arr[2] == "127.0.0.1:8000" || url_arr[2] == "localhost" || url_arr[2] == "localhost:3000") {
	api_domain_name = "https://alpha.crigloo.com";
} else {
	api_domain_name = "https://" + (url_arr[2]);
}
var has_spcial_char = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

// $('input#website').keyup(function () {
// 	var website = $(this).val();
// 	var res = valid_domain(website);
// 	console.log(res);
// 	if(res == false){
// 		$(".website-invalid").show();
// 		$('.website-invalid').addClass('invalid').removeClass('valid');
// 	}else{
// 		$(".website-invalid").hide();
// 		$('.website-invalid').removeClass('invalid').addClass('valid');
// 	}
// });
function valid_domain(domain_name){
	var is_valid = true;
	var f_domain_name = domain_name;
	if(f_domain_name.includes("https://") != true && f_domain_name.includes("http://") != true){
		console.log("1");
		is_valid = false;
	}
	if(f_domain_name.includes("www.") != true){
		console.log("2");
		is_valid = false;
	}
	var r_domain_name = f_domain_name.replace("https://","");
	r_domain_name = r_domain_name.replace("http://","");
	r_domain_name = r_domain_name.replace("www.","");
	var r_domain_name_lst = r_domain_name.split(".");
	console.log(r_domain_name_lst);
	if(r_domain_name_lst.length == 2){
		var first = $.trim(r_domain_name_lst[0]);
		var second = $.trim(r_domain_name_lst[1]);
		if(first == ""){
			is_valid = false;
		}
		if(second == ""){
			is_valid = false;
		}
		if(second.length > 1){
		}else{
			is_valid = false;
		}
	}else{
		is_valid = false;
	}
	console.log(is_valid);
	return is_valid;
}
function aps_register(obj, frm) {
	$(".aps-register-error-msg").html("");
	var first_name = $.trim($("#FormFirstName").val());
	var last_name = $.trim($("#FormLastName").val());
	var user_email = $.trim($("#emailInputSignin").val());
	var user_password = $.trim($("#FormPassword").val());
	var signup_business_type = $.trim($("#signup_business_type").val());
	var website = $.trim($("#website").val());
	var monthly_online_advertising_budget = $.trim($("#signup_budget").val());
	var valid = true;
	if (first_name == '' || last_name == '' || user_email == '' || user_password == '' || signup_business_type == '' || website == '' || monthly_online_advertising_budget == '') {
		valid = false;
	}
	// var chk_pwd_has_cap = [];
	var chk_pwd_has_num = [];
	var chk_pwd_has_lower_cap = [];
	var pwd = user_password.split("");
	if (pwd.length < 8) {
		valid = false;
	}
	for (var pwd_i = 0; pwd_i < pwd.length; pwd_i++) {
		// if (pwd[pwd_i] === pwd[pwd_i].toUpperCase() && pwd[pwd_i] !== pwd[pwd_i].toLowerCase()) {
		// 	chk_pwd_has_cap.push("1");
		// }
		if (pwd[pwd_i] === pwd[pwd_i].toLowerCase() && pwd[pwd_i] !== pwd[pwd_i].toUpperCase()){
			chk_pwd_has_lower_cap.push("1");
		}
		if (has_number.test(pwd[pwd_i])) {
			chk_pwd_has_num.push("1");
		}
	}
	if(chk_pwd_has_lower_cap.length == 0){
		valid = false;
	}
	// if (chk_pwd_has_cap.length == 0) {
	// 	valid = false;
	// }
	if (chk_pwd_has_num.length == 0) {
		valid = false;
	}
	// if (!has_spcial_char.test(user_password)) {
	// 	valid = false;
	// }
	// if (!valid_domain(website)) {
	// 	valid = false;
	// }
	if (valid === true) {
		$(obj).attr("disabled", true);
		$(obj).html("Processing...");
		$.ajax({
			url: appsumo_api_url + "set_new_user",
			type: "post",
			data: JSON.stringify({ "first_name": first_name, "last_name": last_name, "user_email": user_email, "user_password": user_password, "signup_business_type": signup_business_type, "website": website, "monthly_online_advertising_budget": monthly_online_advertising_budget, "domain_name": api_domain_name, "crinst": crinst }),
			dataType: "json",
			contentType: "application/json; charset=utf-8",
			crossDomain: !0,
			error: function (o) {
				console.log(o);
				$(obj).attr("disabled", false);
				$(obj).html("Register");
			},
			success: function (r) {
				var r_key_lst = Object.keys(r);
				if (r_key_lst.indexOf('result') != -1 && r_key_lst.indexOf('message') != -1) {
					if (r['result'] === "success") {
						setTimeout(function () {
							window.location.href = "/aps_register_success.html?user_email=" + String(user_email)
						}, 1500);
					} else {
						$(obj).attr("disabled", false);
						$(obj).html("Register");
						$(".aps-register-error-msg").html(r['message']);
					}
				} else {
					$(obj).attr("disabled", false);
					$(obj).html("Register");
					$(".aps-register-error-msg").html("Unknown Error. Please contact at info@crigloo.com.");
				}
			}
		});
	}
}
function check_user_schema_exists() {
	if (chk_error_lst.length == 5) {
		$(".checking-error-msg").show();
		clearInterval(check_user_schema_interval);
	}
	var query_string_obj = {}
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split("=");
		query_string_obj[pair[0]] = pair[1];
	}
	var query_string_obj_key_lst = Object.keys(query_string_obj);
	if (query_string_obj_key_lst.indexOf('user_email') != -1) {
		var user_email = query_string_obj['user_email'];
		$.ajax({
			url: appsumo_api_url + "check_user_schema_exists",
			type: "post",
			data: JSON.stringify({ "user_email": user_email }),
			dataType: "json",
			contentType: "application/json; charset=utf-8",
			crossDomain: !0,
			error: function (o) {
				console.log(o);
			},
			success: function (r) {
				var r_key_lst = Object.keys(r);
				if (r_key_lst.indexOf('result') != -1 && r_key_lst.indexOf('message') != -1) {
					if (r['result'] === "success" && r['message'] === "created") {
						clearInterval(check_user_schema_interval);
						window.location.href = "https://app.crigloo.com/app/login";
					} else if (r['result'] === "error") {
						chk_error_lst.push("1");
					}
				} else {
					chk_error_lst.push("1");
					$(".checking-error-msg").show();
				}
			}
		});
	}
}

function register(obj, e) {
	$(".register-msg").addClass("d-none").removeClass("text-danger").removeClass("text-success");
	var api_url = "http://127.0.0.1:8007/user_register";
	api_url = "https://rn55l82qph.execute-api.us-east-1.amazonaws.com/api/user_register";
	if(crinst == "neergrc"){
		api_url = "https://cxc5hotewa.execute-api.us-east-1.amazonaws.com/api/user_register";
	}else if(crinst == "eulbrc"){
		api_url = "https://upibw1sm17.execute-api.us-east-1.amazonaws.com/api/user_register";
	}else{
		api_url = "https://rn55l82qph.execute-api.us-east-1.amazonaws.com/api/user_register";
	}
	var first_name = $.trim($("#FormFirstName").val());
	var last_name = $.trim($("#FormLastName").val());
	var user_email = $.trim($("#emailInputSignin").val());
	var user_password = $.trim($("#FormPassword").val());
	var signup_business_type = $.trim($("#signup_business_type").val());
	var monthly_online_advertising_budget = $.trim($("#signup_budget").val());
	var website = $.trim($("#website").val());
	var signup_plan_id = $.trim($(".signupPlanDropdown").find("li.selected").attr("value"));
	//console.log(signup_plan_id);
	var valid = true;
	if (first_name == '' || last_name == '' || user_email == '' || user_password == '' || signup_business_type == '' || website == '' || monthly_online_advertising_budget == '' || signup_plan_id == ''){
		valid = false;
	}
	if (user_email != "" && user_email.indexOf("@") != -1) {
		var user_email_lst = user_email.split("@");
		if (user_email_lst.length == 2) {
			var user_email_1 = user_email_lst[0];
			if (user_email_1 == "") {
				valid = false;
				$("#emailInputSignin").focus();
			}
			var user_email_2 = user_email_lst[1];
			if (user_email_2 == "") {
				valid = false;
				$("#emailInputSignin").focus();
			} else {
				var user_email_lst1 = user_email_2.split(".");
				if (user_email_lst1.length == 2) {
					var user_email_3 = user_email_lst1[0];
					if (user_email_3 == "") {
						valid = false;
						$("#emailInputSignin").focus();
					}
					var user_email_4 = user_email_lst1[1];
					if (user_email_4 == "") {
						valid = false;
						$("#emailInputSignin").focus();
					}
				} else {
					valid = false;
					$("#emailInputSignin").focus();
				}
			}
		} else {
			valid = false;
			$("#emailInputSignin").focus();
		}
	} else {
		valid = false;
		$("#emailInputSignin").focus();
	}
	//var chk_pwd_has_cap = [];
	var chk_pwd_has_num = [];
	var chk_pwd_has_lower_cap = [];
	var pwd = user_password.split("");
	if (pwd.length < 8) {
		valid = false;
	}
	for (var pwd_i = 0; pwd_i < pwd.length; pwd_i++) {
		// if (pwd[pwd_i] === pwd[pwd_i].toUpperCase() && pwd[pwd_i] !== pwd[pwd_i].toLowerCase()) {
		// 	chk_pwd_has_cap.push("1");
		// }
		if (pwd[pwd_i] === pwd[pwd_i].toLowerCase() && pwd[pwd_i] !== pwd[pwd_i].toUpperCase()){
			chk_pwd_has_lower_cap.push("1");
		}
		if (has_number.test(pwd[pwd_i])) {
			chk_pwd_has_num.push("1");
		}
	}
	if(chk_pwd_has_lower_cap.length == 0){
		valid = false;
	}
	// if (chk_pwd_has_cap.length == 0) {
	// 	valid = false;
	// }
	if (chk_pwd_has_num.length == 0) {
		valid = false;
	}
	// if (!has_spcial_char.test(user_password)) {
	// 	valid = false;
	// }
	// if (!valid_domain(website)) {
	// 	valid = false;
	// }
	var referrelval = sessionStorage.getItem('fpr');
		referrelval = referrelval ? referrelval: ''
	if (valid === true) {
		var r_api_domain_name = api_domain_name.replace("https://", "");
		var r_s = JSON.stringify({
			"first_name": first_name,
			"last_name": last_name,
			"user_email": user_email,
			"user_password": user_password,
			"company_name": "",
			"industry_type": "",
			"business_type": signup_business_type,
			"user_phone": "",
			"monthly_online_advertising_budget": monthly_online_advertising_budget,
			"website": website,
			"domain_name": r_api_domain_name,
			"crigloo_product_id": signup_plan_id,
			"referrer": "fpr",
            "referrer_code": referrelval,

		});
		$(obj).attr("disabled", true);
		$(obj).html("Processing...");
		$.ajax({
			url: api_url,
			type: "post",
			data: r_s,
			dataType: "json",
			contentType: "application/json; charset=utf-8",
			crossDomain: !0,
			error: function (s) {
				$(obj).attr("disabled", false);
				$(obj).html("REQUEST FREE TRIAL");
			},
			success: function (s) {
				if (typeof (s['result']) != 'undefined' && s['result'] == "success" && typeof (s['message']) != 'undefined') {
					$("#saveRegisterForm").removeClass("was-validated");
					sessionStorage.setItem('fpr','');
					setTimeout(function () {
						window.location.href = "/register_success.html"
					}, 1500);
					//$(".register-msg").removeClass("d-none").addClass("text-success").html("<b>Thank you for registering. Please check your email for verification. Email sender id is info@crigloo.com</b>");
					$(".register-msg").removeClass("d-none").addClass("text-success").html("");
				} else if (typeof (s['result']) != 'undefined' && s['result'] == "error" && typeof (s['message']) != 'undefined') {
					var s_m = s['message'].split(":");
					if(s_m.length == 2){
						var last_char_len = s_m[1].length;
						var last_char = s_m[1][last_char_len-1];
						var error_msg = s_m[1];
						if(last_char != "."){
							error_msg += ".";
						}
						$(".register-msg").removeClass("d-none").addClass("text-danger").html("<b>"+error_msg+"</b>");
						$(obj).attr("disabled", false);
						$(obj).html("REQUEST FREE TRIAL");
					}else if(s_m.length == 3){
						var last_char_len = s_m[2].length;
						var last_char = s_m[2][last_char_len-1];
						var error_msg = s_m[2];
						if(last_char != "."){
							error_msg += ".";
						}
						$(".register-msg").removeClass("d-none").addClass("text-danger").html("<b>"+error_msg+"</b>");
						$(obj).attr("disabled", false);
						$(obj).html("REQUEST FREE TRIAL");
					}else{
						$(".register-msg").removeClass("d-none").addClass("text-danger").html("<b>" + s['message'] + "</b>");
						$(obj).attr("disabled", false);
						$(obj).html("REQUEST FREE TRIAL");
					}
				} else {
					$(".register-msg").removeClass("d-none").addClass("text-danger").html("<b>Something went wrong. Please contact us at info@crigloo.com.</b>");
					$(obj).attr("disabled", false);
					$(obj).html("REQUEST FREE TRIAL");
				}
			}
		});
	}else{
		iziToast.destroy();
		iziToast.show({
			class: "danger-toast",
			icon: "sl sl-icon-close",
			title: "",
			message: "Please fill in the required fields. *",
			titleColor: "#fff",
			messageColor: "#fff",
			iconColor: "#fff",
			backgroundColor: "#FF7273",
			progressBarColor: "#444F60",
			position: "topRight",
			transitionIn: "bounceInLeft",
			transitionOut: "bounceOutLeft",
			close: false,
			zindex: 99999,
		});
	}
}
function email_verify_success(){
	$('#response_preloader').addClass('d-none');
	$("#response_msg").text('Thank you for successfully verifying your email address. You can now login.').removeClass("d-none");
	$('.login-button').removeClass('d-none');
	$('.backToHome').addClass('d-none');
}
function email_verify_error(){
	$('#response_preloader').addClass('d-none');
	$("#response_msg").text('Thank you for verifying your email address. We will get back to you shortly. You can also contact us at info@crigloo.com.').removeClass("d-none");
}